import React, { useCallback, useEffect, useState } from 'react';
import ArrowRightEndOnRectangle from '../graphics/arrow-right-end-on-rectangle.svg'; // Adjust the path to your SVG file
import ArrowUturnLeft from '../graphics/arrow-uturn-left.svg'; // Adjust the path to your SVG file

import Project from "./Project";
import { useAuth } from "../AuthContext";
import './Dashboard.css';
import Logo from '../graphics/logo-cpv.png';

const Dashboard = () => {
    const { setIsAuthenticated } = useAuth();

    const [projects, setProjects] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [userMessage, setUserMessage] = useState(''); // Add state for user message

    const onLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    const reqProjects = useCallback(async () => {
        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/users/get-projects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
        });

        if (response.status === 401) {
            setIsAuthenticated(false);
        } else {
            const data = await response.json();
            if (response.ok) {
                if (data.success === true) {
                    setProjects(data.projects);
                } else {
                    setProjects(null);
                    setUserMessage('Failed to load projects.');
                }
            } else {
                setUserMessage('Error retrieving projects.');
            }
        }
    }, [setIsAuthenticated]);

    useEffect(() => {
        reqProjects();
    }, [reqProjects]);

    return (
        <div className="dashboard-container">
            <div className="header">
                <img src={Logo} alt="Logo" className="logo" />
                <button className="logout-button" onClick={onLogout}>
                    Logout
                    <img src={ArrowRightEndOnRectangle} alt="Logout Icon" className="icon-logout" />
                </button>
                {selectedProject !== null ? (
                <button className="back-button" onClick={() => {
                    setSelectedProject(null);
                    setUserMessage('');
                }}>
                    <img src={ArrowUturnLeft} alt="Back Icon" className="icon-back" />
                    Back
                </button>) : (<></>)}
            </div>
            {selectedProject !== null ? (
                <div className="project-details">

                    {userMessage && (
                        <div className="user-message">
                            {userMessage}
                        </div>
                    )}
                    <Project userMessage={userMessage} setUserMessage={setUserMessage} selectedProject={selectedProject} />
                </div>
            ) : (
                <div className="projects-list-container">
                    <h1>Projects</h1>
                    {projects === null || projects.length === 0 ? (
                        <p className="no-projects">None</p>
                    ) : (
                        <ul className="projects-list">
                            {projects.map((project, index) => (
                                <li key={index} className="project-item">
                                    <button className="project-button" onClick={() => setSelectedProject(project)}>{project.name}</button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dashboard;
