import './App.css';
import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import {AuthProvider, useAuth} from "./AuthContext"; // Create a Dashboard component for logged-in users

const App = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/users/validate-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            },
          });

          if (response.ok) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('token'); // Remove invalid token
          }
        } catch (error) {
          console.error('Token validation error:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    validateToken();
  }, [setIsAuthenticated]);





  return (
      <div className="App">
        {isAuthenticated ? (
            <Dashboard />
        ) : (
            <Login />
        )}
      </div>
  );
};

export default App;
