import React, { useState } from 'react';
import './Login.css';
import {useAuth} from "../AuthContext"; // Optional: Create a CSS file for styling
import Logo from '../graphics/logo-cpv.png'

const Login = () => {
    const { isAuthenticated, setIsAuthenticated } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        console.log('set isAuthenticated to true');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Use environment variable for API URL
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log(`${apiUrl}/users/login`);
        const response = await fetch(`${apiUrl}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: username, password }),
        });

        const data = await response.json();
        if (response.ok && data.token) {
            onLogin(data.token); // Call onLogin with the token
        } else {
            console.error('Login failed');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <img src={Logo} alt="Logo" />
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
