import React, { useState, useEffect } from 'react';
import { useAuth } from "../AuthContext";
import './Project.css';

const Project = ({ selectedProject, userMessage, setUserMessage }) => {
    const { setIsAuthenticated } = useAuth();
    const [project, setProject] = useState(null);
    const [units, setUnits] = useState(null);
    const [schema, setSchema] = useState(null);

    const fetchProjectDetails = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${apiUrl}/users/get-project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                },
                body: JSON.stringify({ _id: selectedProject.id })
            });

            if (response.status === 401) {
                setIsAuthenticated(false);
            } else {
                const data = await response.json();
                if (response.ok && data.success === true) {
                    setProject(data.project);
                    setUnits(data.project.units);
                    setSchema(data.project.schema);
                } else {
                    setUserMessage('Failed to retrieve project from database.');
                    setProject(null);
                    setUnits(null);
                    setSchema(null);
                }
            }
        } catch (error) {
            setUserMessage('Error fetching project details');
            setProject(null);
            setUnits(null);
            setSchema(null);
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        fetchProjectDetails();
    }, [selectedProject.id]);

    const handleInputChange = (unitIndex, key, value) => {
        const updatedUnits = Object.values(units).map((unit, index) => {
            if (index === unitIndex) {
                return { ...unit, [key]: value };
            }
            return unit;
        });
        setUnits(updatedUnits);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${apiUrl}/users/update-project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                },
                body: JSON.stringify({ _id: selectedProject.id, units }),
            });
            if (response.ok) {
                setUserMessage('Project updated successfully [' + formatDateNow() + ']');
            } else {
                console.error('Failed to update project');
            }
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const formatDateNow = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const renderInput = (unitIndex, key, value) => {
        if (!schema) return null;

        if (typeof value === 'number') {
            const { min, max } = schema[key] || { min: 0, max: 10000000 };
            return (
                <input
                    type="number"
                    value={value}
                    min={min}
                    max={max}
                    onChange={(e) => handleInputChange(unitIndex, key, parseFloat(e.target.value))}
                />
            );
        } else if (typeof value === 'string') {
            if (schema[key] && Array.isArray(schema[key])) {
                const options = schema[key];
                return (
                    <select value={value} onChange={(e) => handleInputChange(unitIndex, key, e.target.value)}>
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
            }
            return (
                <input
                    type="text"
                    value={value}
                    onChange={(e) => handleInputChange(unitIndex, key, e.target.value)}
                />
            );
        }
        return null;
    };

    return (
        <div className="project-container">
            {project ? (
                <div className="project-content">
                    <h1 className="project-title">{project.name}</h1>
                    <p className="project-description">{project.description}</p>
                    {units && Object.keys(units).length > 0 ? (
                        <form onSubmit={handleSubmit}>
                            <div className="table-container">
                                <table className="project-table">
                                    <thead>
                                    <tr>
                                        {Object.keys(units[Object.keys(units)[0]]).map((key) => (
                                            <th key={key} className="project-table-header">{key}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.values(units).map((unit, unitIndex) => (
                                        <tr key={unitIndex}>
                                            {Object.keys(unit).map((key) => (
                                                <td key={key} className="project-table-cell">
                                                    {renderInput(unitIndex, key, unit[key])}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="save-button-container">
                                <button type="submit" className="submit-button">Save Changes</button>
                            </div>
                        </form>
                    ) : (
                        <p className="no-units-message">No units available.</p>
                    )}
                </div>
            ) : (
                <p className="error-message">Error retrieving project details.</p>
            )}
        </div>
    );

};

export default Project;
